import { graphql } from 'gatsby'
import React, { FC } from 'react'
import Layout from '@/containers/Layout'
import PageContainer from '@/containers/PageContainer'
import { Page } from 'web/types/custom-graphql-types'
import SEO from '@/components/SEO'

interface PageProps {
  data: {
    page: Page
  }
}

const AnyPage: FC<PageProps> = ({ data: { page }, ...props }) => (
  <Layout
    title={page.title}
    _type={'page'}
    i18n_lang={page.i18n_lang}
    i18n_refs={page.i18n_refs}
    i18n_base={page.i18n_base}
    seoSettings={page.seoSettings}
  >
    <PageContainer page={page} />
  </Layout>
)

export default AnyPage

export const Head: FC<PageProps> = ({ data: { page } }) => <SEO seoSettings={page.seoSettings} i18n_lang={page.i18n_lang} />

export const query = graphql`
  query ($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      seoSettings {
        ...seoSettingsData
      }
      contentModules {
        ...contentModulesData
      }
      i18n_lang
      i18n_refs {
        ... on SanityPage {
          _type
          slug {
            current
          }
          i18n_lang
        }
      }
      i18n_base {
        ... on SanityPage {
          _type
          slug {
            current
          }
          i18n_lang
        }
      }
    }
  }
`
