import React, { FC } from 'react'
import { Page } from 'web/types/custom-graphql-types'
import ModulesLoop from '@/modules'

interface PageProps {
  page: Page
}
const PageContainer: FC<PageProps> = (props) => {
  const { contentModules, i18n_lang } = props.page

  return <ModulesLoop modules={contentModules?.modules} i18n_lang={i18n_lang} templateType="page" />
}

export default PageContainer
